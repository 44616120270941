<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap32Templates"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 32: Templates" image-name="template.jpg" image-alt="Templates"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a template?</p></li>
<li><p>How to create a template.</p></li>
<li><p>How to inject dynamic data into templates.</p></li>
<li><p>How to print items from a collection (slice, array, map).</p></li>
<li><p>How to embed a template file into your Go binary.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>template</p></li>
<li><p>nesting / nested</p></li>
<li><p>binary</p></li>
</ul>
<div id="what-is-a-web-page-template" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is a web page template <a href="#what-is-a-web-page-template"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A template is the skeleton of a web page. It defines its layout and where dynamic data will be injected when a user sends a request to the web server.</p>
<p>To better understand this definition, we will take the example of an e-commerce website. As a developer, you are instructed to create product pages. The website has only three products to show. That’s pretty easy; you will create three pages (with HTML and CSS) to present the products. You will ask the marketing team to produce the commercial texts and give you pictures of the products. This task will not take you long days to perform.</p>
<p>Imagine now that the marketing guy comes to you ten months later and announces that they will introduce 100 new products to the catalog. You are asked to develop the pages for the products.</p>
<p>You have two options :</p>
<ul>
<li><p>You code manually 100 pages</p></li>
<li><p>You use some kind of automatism</p></li>
</ul>
<p>The first solution will take a lot of time for you and the whole team, whereas the second solution seems smarter.</p>
<p>The idea is to develop just one product page. For the product name, you will put a placeholder, for the description another one, and for the price another one. Then this idea is to inject product data into the right placeholders dynamically.</p>
<p>A template represents the model of some kind of page (for instance, the product page) or part of a web page (the navigation bar). Data are injected into a template into placeholders. The underlying data often comes from the application’s persistence layer (a database, a cache system...). This data is structured to be used easily in the template.</p>
<p>You can also use templates to generate emails, pdfs, or other documents. In this chapter, we will focus on the web use case.</p>
<div id="why-is-it-useful" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Why is it useful <a href="#why-is-it-useful"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>It allows you to gain considerable time when you develop a website. You do not have to repeat yourself.</p></li>
<li><p>The designers are often taught in school to create and maintain templates. Many templating engines exist (for each language) with their specialties, but they share common features that are easy to understand.</p></li>
<li><p>Designers and programmers can work independently after they have specified the data structure passed to the template.</p></li>
</ul>
<div id="two-template-packages" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Two template packages <a href="#two-template-packages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go has two main packages for handling templating :</p>
<ol type="1">
<li><p>text/template</p></li>
<li><p>html/template</p></li>
</ol>
<p>The first one can be used for text output when there is no injection risk. You can use the second one for formatting HTML pages. In the second one, Go provides a protection mechanism against bad users that will inject code into their input to attack your website.</p>
<p>If you plan to generate HTML, you should use the <span v-highlightjs><code class="go" v-pre style="display: inline">html/template</code></span> package an NOT the <span v-highlightjs><code class="go" v-pre style="display: inline">text/template</code></span> !</p>
<p>Note also that you should always validate data injected into templates. You should never trust data generated by users.</p>
<div id="getting-started-with-templates." class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Getting started with templates. <a href="#getting-started-with-templates."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the next section, we will take the example of an e-commerce website with a database of thousands of products.</p>
<p>The first thing to do is to create the template. Firstly, we will look at a standard HTML page for a product page.</p>
<div id="view" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> View <a href="#view"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >&lt;!--views/product.html--&gt;
&lt;!DOCTYPE html&gt;
&lt;html&gt;
&lt;head&gt;
    &lt;title&gt;Red Tea Pot 250ml&lt;/title&gt;
&lt;/head&gt;
&lt;body&gt;
    &lt;h1&gt;Red Tea Pot 250ml&lt;/h1&gt;
    &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. &lt;/p&gt;
    &lt;p&gt;Price : &lt;strong&gt;23.99&lt;/strong&gt; $&lt;/p&gt;
    &lt;h2&gt;Delivery options&lt;/h2&gt;
        &lt;ul&gt;
            &lt;li&gt;Express + 2$ &lt;/li&gt;
            &lt;li&gt;Ultra Express + 5$&lt;/li&gt;
            &lt;li&gt;Normal&lt;/li&gt;
        &lt;/ul&gt;
    &lt;h3&gt;This was a {{.}}&lt;/h3&gt;
&lt;/body&gt;

&lt;/html&gt;</code></pre>
<p>This page contains details about our product. We have the product name, description, price, and delivery options. We will store this file in the directory “views”. This name is common in the web industry (it makes an implicit reference to the MVC model: Model View Controller).</p>
<p>Take a close look at the line :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;h3&gt;This was a {{.}}&lt;/h3&gt;</code></pre>
<p>The notation you see (the double curly braces) refers to a template variable. We will see in the next sect how go handles it.</p>
<div id="web-server" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Web server <a href="#web-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s create the web server of our website :</p>
<pre v-highlightjs><code class="go" v-pre >// template/basic/main.go 

func main() {
    http.HandleFunc(&quot;/red-tea-pot&quot;, redTeaPotHandler)
    if err := http.ListenAndServe(&quot;localhost:8080&quot;, nil); err != nil {
        panic(err)
    }
}</code></pre>
<p>We will listen to incoming connexions on the localhost on port 8080.</p>
<div id="request-handler" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> Request handler <a href="#request-handler"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If we receive a request to the route <span v-highlightjs><code class="go" v-pre style="display: inline">/red-tea-pot</code></span> the <span v-highlightjs><code class="go" v-pre style="display: inline">redTeaPotHandler</code></span> function will be launched :</p>
<pre v-highlightjs><code class="go" v-pre >// template/basic/main.go 
func redTeaPotHandler(w http.ResponseWriter, r *http.Request) {
    tmpl, err := template.ParseFiles(&quot;./views/product.html&quot;)
    if err != nil{
        http.Error(w, &quot;Something went wrong&quot;, http.StatusInternalServerError)
        return
    }

    err = tmpl.Execute(w, &quot;test&quot;)
    // handle error
}</code></pre>
<p>We have defined a request handler for our server in the last code snippet. It takes as argument a <span v-highlightjs><code class="go" v-pre style="display: inline">http.ResponseWriter</code></span> and a pointer to a <span v-highlightjs><code class="go" v-pre style="display: inline">http.Request</code></span>.</p>
<p>It’s a classical HTTP handler. If you want to get more information about how to create a web server take a look at the dedicated chapter (<a href="#chap:Basic-HTTP-Server" data-reference-type="ref" data-reference="chap:Basic-HTTP-Server">[chap:Basic-HTTP-Server]</a>).</p>
<div id="template-parsing-and-execution" class="anchor"></div>
<h4 data-number="6.3.0.1"><span class="header-section-number">6.3.0.1</span> Template parsing and execution <a href="#template-parsing-and-execution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The first operation to do is to load the template file and parse it. For this operation, we will use the function <span v-highlightjs><code class="go" v-pre style="display: inline">template.ParseFiles</code></span>. This function can take as argument more than one file path. In the example, we only take a single file path <span v-highlightjs><code class="go" v-pre style="display: inline">"./views/product.html"</code></span>. The method returns a pointer to a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">template.Template</code></span>.</p>
<p>For each of the file paths in arguments, the function <span v-highlightjs><code class="go" v-pre style="display: inline">template.ParseFiles</code></span> will :</p>
<ul>
<li><p>Load the file from the filesystem (it uses <span v-highlightjs><code class="go" v-pre style="display: inline">ioutil.ReadFile</code></span>)</p></li>
<li><p>Generate the <strong>name</strong> of the template based on the last element of its path. In our case, the name will be “product.html”</p></li>
<li><p>Allocate the new HTML Template</p></li>
<li><p>The template will then be parsed</p></li>
</ul>
<p>Then once we have loaded and parsed our template, we will call the method<span v-highlightjs><code class="go" v-pre style="display: inline">Execute</code></span>. It takes to arguments an <span v-highlightjs><code class="go" v-pre style="display: inline">io.Writer</code></span> and a second one which represents the data to inject into the template.</p>
<p>We have nothing to inject in our example because our template is not dynamic; every element is fixed.</p>
<div id="template-actions" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Template actions <a href="#template-actions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In a template, you can add “actions” that will indicate to the system to do something. The official definition of actions are “data evaluations or control structures”.</p>
<p>All <strong>actions</strong> are delimited by <strong>double curly braces</strong>.</p>
<p>Let’s take an example of the simplest action inside a template:</p>
<pre v-highlightjs><code class="go" v-pre >&lt;h3&gt;This was a {{.}}&lt;/h3&gt;</code></pre>
<p>Here we are asking the templating engine to print the value of the second argument of the <span v-highlightjs><code class="go" v-pre style="display: inline">template.Execute</code></span> method. <span v-highlightjs><code class="go" v-pre style="display: inline">{{ . }}</code></span> is a <strong>template directive</strong>. The template engine defines a pseudo-scripting language. You will see that this scripting language is very close to Go.</p>
<div id="dot-notation" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Dot notation <a href="#dot-notation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The dot sign represents the data passed to the template. To access a property, you just have to write a dot then the property’s name. For instance, if I want to access to the property <span v-highlightjs><code class="go" v-pre style="display: inline">Price</code></span> from the data passed to the template, I use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >{{.Price}}</code></pre>
<p>In this configuration with the dot notation, we are using the <strong>global context</strong>. This is not always true. When you use the dot inside an iteration, the dot do <strong>NOT</strong> represent the global context, but the iteration context. Let’s take an example you will understand immediately.</p>
<p>If you have defined the property Price, you can access it from everywhere in the template like this :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;{{ .Price }}&lt;/p&gt;
&lt;&lt;!-- Equivalent notation --&gt;
&lt;p&gt;{{ $.Price }}&lt;/p&gt;</code></pre>
<p>We have two notations <span v-highlightjs><code class="go" v-pre style="display: inline">.Price</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">$.Price</code></span> point to the same value. The dot gives you access to the global data context of the template.</p>
<p>Inside an iteration, the dot is equal to the current iteration value :</p>
<pre v-highlightjs><code class="go" v-pre >{{range  .ShippingOptions}}
   &lt;li&gt;{{ . }}&lt;/li&gt;
{{end}}</code></pre>
<p>Here the dot is equal for the first iteration to <span v-highlightjs><code class="go" v-pre style="display: inline">"Extra Priority"</code></span> then to <span v-highlightjs><code class="go" v-pre style="display: inline">"Normal"</code></span> and finally to <span v-highlightjs><code class="go" v-pre style="display: inline">"Low Priority"</code></span>. The template engine will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;li&gt;Extra Priority&lt;/li&gt;
&lt;li&gt;Normal&lt;/li&gt;
&lt;li&gt;Low Priority&lt;/li&gt;</code></pre>
<p>But what if you want to access the property <span v-highlightjs><code class="go" v-pre style="display: inline">Price</code></span> (which is in the global context)? With the <span v-highlightjs><code class="go" v-pre style="display: inline">$</code></span> sign you can access the global context</p>
<pre v-highlightjs><code class="go" v-pre >{{range  .ShippingOptions}}
    &lt;li&gt;Product Price : {{ $.Price}} : {{ . }}&lt;/li&gt;
{{end}}</code></pre>
<p>The previous template will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;li&gt;Product Price : 100 : Extra Priority&lt;/li&gt;
&lt;li&gt;Product Price : 100 : Normal&lt;/li&gt;
&lt;li&gt;Product Price : 100 : Low Priority&lt;/li&gt;</code></pre>
<div id="print-text" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Print text <a href="#print-text"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This is the main usage of a template. The backend gives you a dataset, and you have to inject it into an HTML file.</p>
<p>The first thing to do is to define a type struct that will structure the data :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    Name        string
    Price       string
    Description string
}</code></pre>
<p>We have a very simple type struct with three text fields. Then we create a variable of this type :</p>
<pre v-highlightjs><code class="go" v-pre >teaPot := Product{Name: &quot;Red Tea Pot 250ml&quot;, Description: &quot;Test&quot;, Price: &quot;19.99&quot;}</code></pre>
<p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">teaPot</code></span> contains all the data necessary to fill a product page :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;!DOCTYPE html&gt;
&lt;html&gt;
&lt;head&gt;
    &lt;title&gt;{{.Name}}&lt;/title&gt;
&lt;/head&gt;
&lt;body&gt;
&lt;h1&gt;{{.Name}}&lt;/h1&gt;
&lt;p&gt;{{.Description}}&lt;/p&gt;
&lt;p&gt;Price : &lt;strong&gt;{{.Price}}&lt;/strong&gt; $&lt;/p&gt;
&lt;h2&gt;Delivery options&lt;/h2&gt;
&lt;ul&gt;
    &lt;li&gt;Express + 2$ &lt;/li&gt;
    &lt;li&gt;Ultra Express + 5$&lt;/li&gt;
    &lt;li&gt;Normal&lt;/li&gt;
&lt;/ul&gt;
&lt;/body&gt;

&lt;/html&gt;</code></pre>
<p>Note that each action begins with a point <span v-highlightjs><code class="go" v-pre style="display: inline">"."</code></span>. We will inject the property <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> into the HTML tag title.<span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> is also injected into the tag h1.</p>
<p>To inject the value of the property <span v-highlightjs><code class="go" v-pre style="display: inline">Foo</code></span> we just have to use the following action :</p>
<pre v-highlightjs><code class="go" v-pre >{{.Foo}}</code></pre>
<p>Once our view and the data variable are ready, we just have to pass them to the Execute method :</p>
<pre v-highlightjs><code class="go" v-pre >err = tmpl.Execute(w, teaPot)</code></pre>
<p>You can see the template execution result in the figure <a href="#fig:Template-execution-result" data-reference-type="ref" data-reference="fig:Template-execution-result">1</a>.</p>
<figure>
<b-img :src="require('@/assets/images/template_execution.png')" alt="Template execution result (web view)[fig:Template-execution-result]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Template execution result (web view)<span id="fig:Template-execution-result" label="fig:Template-execution-result">[fig:Template-execution-result]</span></figcaption>
</figure>
<div id="remove-white-spaces-before-and-after-any-directive" class="anchor"></div>
<h3 data-number="9.0.1"><span class="header-section-number">9.0.1</span> Remove white spaces before and after any directive <a href="#remove-white-spaces-before-and-after-any-directive"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>If you want to remove white spaces (space, horizontal tab, carriage return, and newline) in the test that exactly precede your template directive, you can use the syntax :</p>
<pre v-highlightjs><code class="go" v-pre >{{- .MyVar}}</code></pre>
<p>To remove white spaces in what follows your directive, use this syntax :</p>
<pre v-highlightjs><code class="go" v-pre >{{.MyVar -}}</code></pre>
<p>Note that it will not trim the variable MyVar but the text surrounding the action.</p>
<p>Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;42 {{&quot;hello !&quot;}}&lt;/p&gt;</code></pre>
<p>Will output : “42 hello !”. But :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;42 {{- &quot;hello !&quot;}}&lt;/p&gt;</code></pre>
<p>Will output “42hello !”</p>
<div id="print-dates" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Print dates <a href="#print-dates"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Dates are very common on web pages. You can pass a <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> value to a template. Let’s add the field <span v-highlightjs><code class="go" v-pre style="display: inline">ShippingDate</code></span> to our type struct :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    Name         string
    Price        string
    Description  string
    ShippingDate time.Time
}</code></pre>
<p>Next, we add it to the <span v-highlightjs><code class="go" v-pre style="display: inline">teaPot</code></span> variable :</p>
<pre v-highlightjs><code class="go" v-pre >teaPot := Product{Name: &quot;Red Tea Pot 250ml&quot;, Description: &quot;Test&quot;, Price: &quot;19.99&quot;, ShippingDate: time.Now()}</code></pre>
<p>We can use it in our template with the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;Shipping Date : {{.ShippingDate}}&lt;/p&gt;</code></pre>
<p>But when the template got executed, the following string appears :</p>
<pre v-highlightjs><code class="go" v-pre >Shipping Date : 2018-12-20 13:00:02.338064 +0100 CET m=+20.349586416</code></pre>
<p>This is not a very user-friendly way of format a date! With templates you can call the <span v-highlightjs><code class="go" v-pre style="display: inline">Format</code></span> method :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;Shipping Date : {{.ShippingDate.Format &quot;2006-01-02&quot;}}&lt;/p&gt;</code></pre>
<p>This action will output the following line :</p>
<pre v-highlightjs><code class="go" v-pre >Shipping Date: 2018-12-20</code></pre>
<p>Here <span v-highlightjs><code class="go" v-pre style="display: inline">"2006-01-02"</code></span> is the date layout. You can adapt it for your needs.</p>
<div id="nested-templates" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Nested templates <a href="#nested-templates"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>If your site has more than 1 page, you might consider using nested templates. Why? To avoid repeating yourself when coding templates. For instance, on each page of your website, you will have (practically) the same header, the same footer, the same navbar.</p>
<p>If you choose to copy-paste the code of the navbar on each page of the site you miss one of the most interesting features of a templating engine : nested templates (also called “partials” on some publications).</p>
<div id="define-a-template" class="anchor"></div>
<h4 data-number="11.0.0.1"><span class="header-section-number">11.0.0.1</span> Define a template <a href="#define-a-template"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>It is possible to load a template into another template with a specific syntax. Let’s define two templates: the footer template and the header template. The first defines the header of the HTML page, with the head tag, its title, and the opening body tag :</p>
<pre v-highlightjs><code class="go" v-pre >// views/header.html
{{ define &quot;header&quot; }}
    &lt;!DOCTYPE html&gt;
    &lt;html&gt;
    &lt;head&gt;
        &lt;title&gt;{{ .PageTitle }}&lt;/title&gt;
    &lt;/head&gt;
    &lt;body&gt;
{{ end }}</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">footer</code></span> template is closing the body tag and closing the HTML tag :</p>
<pre v-highlightjs><code class="go" v-pre >{{ define &quot;footer&quot; }}
    &lt;/body&gt;
    &lt;/html&gt;
{{end}}</code></pre>
<p>Note that we used a new action : <span v-highlightjs><code class="go" v-pre style="display: inline">define</code></span>. With it, you can define the contents of a named template :</p>
<pre v-highlightjs><code class="go" v-pre >{{ define &quot;X&quot; }}
    &lt;!--Content of the template named X --&gt;
{{ end }}</code></pre>
<div id="call-a-template" class="anchor"></div>
<h4 data-number="11.0.0.2"><span class="header-section-number">11.0.0.2</span> Call a template <a href="#call-a-template"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Once we have defined the header and footer, it’s time to call them into our main template. As you can see in figure <a href="#fig:Nested-templates" data-reference-type="ref" data-reference="fig:Nested-templates">2</a> the page comprises three sections: the header section, the product section , and the footer section.</p>
<figure>
<b-img :src="require('@/assets/images/nested_templates.png')" alt="Nested templates[fig:Nested-templates]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Nested templates<span id="fig:Nested-templates" label="fig:Nested-templates">[fig:Nested-templates]</span></figcaption>
</figure>
<pre v-highlightjs><code class="go" v-pre >// views/product.html
{{ template &quot;header&quot; . }}
&lt;h1&gt;{{.Name}}&lt;/h1&gt;
&lt;p&gt;{{.Description}}&lt;/p&gt;
&lt;p&gt;Price : &lt;strong&gt;{{.Price}}&lt;/strong&gt; $&lt;/p&gt;
&lt;p&gt;Shipping Date : {{.ShippingDate.Format &quot;2006-01-02&quot;}}&lt;/p&gt;
&lt;h2&gt;Delivery options&lt;/h2&gt;
&lt;ul&gt;
    &lt;li&gt;Express + 2$ &lt;/li&gt;
    &lt;li&gt;Ultra Express + 5$&lt;/li&gt;
    &lt;li&gt;Normal&lt;/li&gt;
&lt;/ul&gt;
{{ template &quot;footer&quot; . }}</code></pre>
<p>To call a specific template (by its name), we are using the following action syntax :</p>
<pre v-highlightjs><code class="go" v-pre >{{ template &quot;header&quot; . }}</code></pre>
<p>Where we are calling the template named <span v-highlightjs><code class="go" v-pre style="display: inline">"header"</code></span> and we are giving it the dot context. This way the “header” template can have access to all variables that are defined in this context. The product page is defined afterward. At the end of the page, we are calling the “footer” template with a similar syntax.</p>
<div id="backend" class="anchor"></div>
<h4 data-number="11.0.0.3"><span class="header-section-number">11.0.0.3</span> Backend <a href="#backend"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>In the backend, do not forget to <strong>load and parse</strong> the three templates to make this works :</p>
<pre v-highlightjs><code class="go" v-pre >// template/nested/main.go 
// ...

tmpl, err := template.ParseFiles(&quot;./views/product.html&quot;,&quot;./views/header.html&quot;,&quot;./views/footer.html&quot;)</code></pre>
<p>Note that here we are loading the product template <strong>first</strong>. If you load one of the two others first, you will end up will a blank page if you use the following syntax into your handler :</p>
<pre v-highlightjs><code class="go" v-pre >err = tmpl.Execute(w, teaPot)</code></pre>
<p>If you want to specify which template to execute (to avoid the blank page error), use this syntax instead :</p>
<pre v-highlightjs><code class="go" v-pre >err = tmpl.ExecuteTemplate(w,&quot;product.html&quot;, teaPot)</code></pre>
<p>Here we are telling go to execute the template named <span v-highlightjs><code class="go" v-pre style="display: inline">"product.html"</code></span> (the default name of a template is the last part of its path).</p>
<div id="variables" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Variables <a href="#variables"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Sometimes it’s useful to store something in a variable for future use. Variable names start with the dollar sign <span v-highlightjs><code class="go" v-pre style="display: inline">"$"</code></span>. You can use the short assignment statement to create a variable :</p>
<pre v-highlightjs><code class="go" v-pre >{{ $myVar := .Name }}</code></pre>
<p>In the previous code snippet, we created the variable <span v-highlightjs><code class="go" v-pre style="display: inline">$myVar</code></span> and assigned to it the value contained in <span v-highlightjs><code class="go" v-pre style="display: inline">.Name</code></span><strong>.</strong> The left part of the assignment can be more complex. In the official documentation, we name the left part of the assignment a “pipeline”.</p>
<p>You can change the value of an existing variable using the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >{{ $myVar = .Price }}</code></pre>
<p>Here we are assigning to $myVar a float value.</p>
<div id="scope-how-to-use-a-variable" class="anchor"></div>
<h4 data-number="12.0.0.1"><span class="header-section-number">12.0.0.1</span> Scope (how to use a variable) <a href="#scope-how-to-use-a-variable"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Any variable is defined inside a scope. The scope represents the lifetime of a variable. When the scope ends, the variable no longer exists.</p>
<ul>
<li><p><u>By default, a variable is defined inside the scope of the template.</u></p></li>
<li><p><u>If you define a variable inside a complex structure</u> (for instance, an if statement) then the variable exists only inside the <strong>scope</strong> of that structure :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >{{ if .Price}}
    {{ $var2 := .Name}}
    // $var2 exists
{{ end}}
// $var2 no longer exists
&lt;p&gt;{{ $var2 }}&lt;/p&gt;</code></pre>
<p>Here we define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">$var2</code></span> “inside” an if statement. The variable <span v-highlightjs><code class="go" v-pre style="display: inline">$var2</code></span> only exists in the scope of the if statement. After the <span v-highlightjs><code class="go" v-pre style="display: inline">{{end}}</code></span> the variable cannot be used.</p>
<div id="call-a-method" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Call a method <a href="#call-a-method"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can call methods into templates. Not all methods can be called :</p>
<ul>
<li><p>The method should have only <strong>one</strong> or <strong>two</strong> return values</p>
<ul>
<li><strong>If two</strong> values are returned, the <strong>second</strong> one must be of type <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span>.</li>
</ul></li>
</ul>
<p>Let’s take an example. We will define the methods <span v-highlightjs><code class="go" v-pre style="display: inline">Foo</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Bar</code></span> with a <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> receiver :</p>
<pre v-highlightjs><code class="go" v-pre >// template/method-call/main.go 
//...

type Product struct {
    //...
}

func (p Product) Foo() string {
    return &quot;FOO&quot;
}

func (p Product) Bar(test string) string {
    return fmt.Sprintf(&quot;Bar : %s&quot;, test)
}</code></pre>
<p>Then you can call the <span v-highlightjs><code class="go" v-pre style="display: inline">Foo</code></span> function this way :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;{{.Foo}}&lt;/p&gt;
&lt;p&gt;{{.Bar &quot;lol&quot;}}&lt;/p&gt;</code></pre>
<p>When the template is executed, it will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;FOO&lt;/p&gt;
&lt;p&gt;Bar : lol&lt;/p&gt;</code></pre>
<div id="predefined-global-functions" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Predefined global functions <a href="#predefined-global-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The template engine has builtin functions that we can use:</p>
<div class="list">
<p>This is a variadic function. It will check the “boolean” value of each argument. When it encounters a false, it will return it directly. When all arguments are true, the function will return the last argument.</p>
</div>
<div id="the-definition-of-true" class="anchor"></div>
<h4 data-number="14.0.0.1"><span class="header-section-number">14.0.0.1</span> The definition of true <a href="#the-definition-of-true"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The answer is not obvious. The function <strong>and</strong> for instance, will check the boolean value of each of its argument.<strong><u>True</u></strong> means :</p>
<ul>
<li><p>An <strong>Array</strong>, a <strong>Map</strong>, a <strong>Slice</strong> a <strong>String</strong> <strong><u>not empty</u></strong> (length &gt; 0)</p></li>
<li><p>A <strong>complex</strong> number <strong><u>not equal to 0</u></strong></p></li>
<li><p>A <strong>function</strong>, a <strong>channel</strong>, a <strong>pointer</strong>, an <strong>interface</strong> that is <strong>not nil</strong></p></li>
<li><p>An integer (signed or unsigned) that is <strong><u>not equal to 0.</u></strong></p></li>
<li><p>A float <strong><u>not equal to 0</u></strong></p></li>
<li><p>A struct (struct are always true)</p></li>
</ul>
<div id="example" class="anchor"></div>
<h4 data-number="14.0.0.2"><span class="header-section-number">14.0.0.2</span> Example: <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Let’s add the property <span v-highlightjs><code class="go" v-pre style="display: inline">Sale</code></span> (bool) and <span v-highlightjs><code class="go" v-pre style="display: inline">SaleImagePath</code></span> (slice of strings) to the data struct type (<span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span>). The idea is to add an image to the page if the product is on sale and if we have at least an image to display.</p>
<p>We will initialize the value of the property <span v-highlightjs><code class="go" v-pre style="display: inline">Sale</code></span> to true and the property <span v-highlightjs><code class="go" v-pre style="display: inline">SaleImagePath</code></span> with a slice of strings containing one string. The function call :</p>
<pre v-highlightjs><code class="go" v-pre >and .Sale .SaleImagePath</code></pre>
<p>Will return <span v-highlightjs><code class="go" v-pre style="display: inline">.SaleImagePath</code></span> because :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">.Sale</code></span> is <strong>true</strong></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">.SaleImagePath</code></span> is <strong>not an empty slice</strong>.</p></li>
</ul>
<p>We can use <strong>and</strong> <span v-highlightjs><code class="go" v-pre style="display: inline">.Sale</code></span> <span v-highlightjs><code class="go" v-pre style="display: inline">.SaleImagePath</code></span> in an if statement</p>
<pre v-highlightjs><code class="go" v-pre >{{ if and .Sale .SaleImagePath}}
    &lt;p&gt;My super image&lt;/p&gt;
    &lt;!--Add image here--&gt;
{{ end }}</code></pre>
<div class="list">
<p>The first argument of this function is... a function/method name. Call allows you to use a function directly inside the template. There are some limitations to the function. It must return one or two values (if two, the second argument must be an error). The function can have as many arguments as you want.</p>
</div>
<p>For example, we define the function <span v-highlightjs><code class="go" v-pre style="display: inline">Bar</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// template/functions/main.go 

func Bar(a string, b string) string {
    buf := bytes.NewBufferString(a)
    buf.WriteString(b)
    return buf.String()
}</code></pre>
<p>This function concatenates to string (with a <span v-highlightjs><code class="go" v-pre style="display: inline">bytes.Buffer</code></span>). If we want to call it inside our template, we must modify our data struct and add the function to it.</p>
<pre v-highlightjs><code class="go" v-pre >// template/functions/main.go 

type Product struct {
    //...
    MyFunc func(string, string)string
}</code></pre>
<p>We name this property <span v-highlightjs><code class="go" v-pre style="display: inline">MyFunc</code></span>. Then we create a new instance of <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span>, and we assign to <span v-highlightjs><code class="go" v-pre style="display: inline">MyFunc</code></span> the <span v-highlightjs><code class="go" v-pre style="display: inline">Bar</code></span> function :</p>
<pre v-highlightjs><code class="go" v-pre >    teaPot := Product{
        //...
        MyFunc:Bar}</code></pre>
<p>Then inside the template, we can call it :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;{{call .MyFunc &quot;first&quot; &quot;second&quot;}}&lt;/p&gt;</code></pre>
<p>Which outputs :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;firstsecond&lt;/p&gt;</code></pre>
<div class="list">
<p>Will return the index of an element in a map</p>
<p>Allow you to escape javascript code</p>
<p>Will return the length of a slice, array, map...</p>
<p>Can be used to print something; the functions called behind are the ones from the fmt package (<span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprint</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintf</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintln</code></span>).</p>
<p>This function takes a variable number of arguments. It will build a string from those arguments and then make a call to a function of the package url : <span v-highlightjs><code class="go" v-pre style="display: inline">QueryEscape</code></span><strong>.</strong> This function allows you to build complex query parts of URLs directly into your template</p>
</div>
<p>The complete list of builtin function can be found here:</p>
<ul>
<li>https://golang.org/pkg/text/template/#hdr-Functions</li>
</ul>
<div id="custom-template-functions" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Custom template functions <a href="#custom-template-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The template package provides you the ability to use custom functions inside your template. You have to create your functions first, then pass them to the template <strong>before</strong> parsing and execution.</p>
<p>We will create a <span v-highlightjs><code class="go" v-pre style="display: inline">template.FuncMap</code></span><strong>.</strong> The type behind <span v-highlightjs><code class="go" v-pre style="display: inline">FuncMap</code></span> is <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]interface{}</code></span> . It’s a map of strings to empty interface. Then we can add to our map the functions we want to add to the template:</p>
<pre v-highlightjs><code class="go" v-pre >var capitalizeFirstLetter = func(text string)string{return strings.Title(text)}</code></pre>
<p>Here we create an anonymous function that will wrap the function <span v-highlightjs><code class="go" v-pre style="display: inline">string.Title</code></span> (which capitalize the first letter of every word of a sentence). The function is stored into a variable named <span v-highlightjs><code class="go" v-pre style="display: inline">capitalizeFirstLetter</code></span>.</p>
<p>Then we add the function to the map :</p>
<pre v-highlightjs><code class="go" v-pre >funcs := template.FuncMap{&quot;capitalizeFirstLetter&quot;: capitalizeFirstLetter}</code></pre>
<p>The key of this map is the name that will be exposed into the template, the second argument is the function itself. We can not directly call <span v-highlightjs><code class="go" v-pre style="display: inline">ParseFiles</code></span>, because the functions have to be added to the template <strong>before</strong> parsing :</p>
<pre v-highlightjs><code class="go" v-pre >// template/custom-functions/main.go 
//...

tmpl, err := template.New(&quot;product-dynamic.html&quot;).Funcs(funcs).ParseFiles(&quot;./views/product-dynamic.html&quot;)
// check error...</code></pre>
<p>Then we can use the function inside the template :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;{{capitalizeFirstLetter &quot;test&quot;}}&lt;/p&gt;</code></pre>
<p>Will output :</p>
<pre v-highlightjs><code class="go" v-pre >Test</code></pre>
<div id="conditionnals" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Conditionnals <a href="#conditionnals"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can use conditional structures (if, then else).</p>
<p>Let’s take an example: In our previous product example, we will add a field to the type struct Product :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    Name         string
    Price        string
    Description  string
    ShippingDate time.Time
    Sale         bool
}</code></pre>
<p>The field is named <span v-highlightjs><code class="go" v-pre style="display: inline">Sale</code></span>. It’s a boolean that will flag a product if it’s on sale. We want to display something special on our page for this occasion, but only if the product is on sale :</p>
<pre v-highlightjs><code class="go" v-pre >{{ if .Sale }}
    &lt;h3&gt;Exclusive Sale Today !&lt;/h3&gt;
{{ end }}</code></pre>
<p>When the template is executed, the engine will check if the value of <span v-highlightjs><code class="go" v-pre style="display: inline">Sale</code></span> is not empty. The “empty” condition covers the following case :</p>
<ul>
<li><p>false</p></li>
<li><p>0</p></li>
<li><p>a nil pointer</p></li>
<li><p>an interface value</p></li>
<li><p>array, slice, map, or string of length zero.</p></li>
</ul>
<p>The “if” can be completed with an “else” action :</p>
<pre v-highlightjs><code class="go" v-pre >{{ if .Sale }}
    &lt;h3&gt;Exclusive Sale Today !&lt;/h3&gt;
{{ else }}
    &lt;p&gt;Not in sale, sorry&lt;/p&gt;
{{ end }}</code></pre>
<p>For more complex comparisons, you can use the eleseif action :</p>
<pre v-highlightjs><code class="go" v-pre >{{ if .Sale }}
    //...
{{ elseif .ExtraDiscount }}
    //...
{{ else }}
    //...
{{ end }}</code></pre>
<div id="comparison-operators" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Comparison operators <a href="#comparison-operators"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/template_comparison.png')" alt="Comparison syntax and binary operators"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Comparison syntax and binary operators</figcaption>
</figure>
<p>You can compare two variables together by using the six existing operators. The syntax is not usual for comparisons. You first specify the comparison operators, and then you specify the two operands. For instance, if you want to compare <span v-highlightjs><code class="go" v-pre style="display: inline">.Price</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">100.00</code></span> together the syntax is <span v-highlightjs><code class="go" v-pre style="display: inline">eq .Price 100.00</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >{{ if eq .Price  100.00 }}
    &lt;p&gt;Only 100$&lt;/p&gt;
{{ else }}
    &lt;p&gt;Give yourself a treat&lt;/p&gt;
{{ end }}</code></pre>
<p>Those comparisons will return boolean values that can be used safely with conditionals statements.</p>
<div id="warning" class="anchor"></div>
<h4 data-number="17.0.0.1"><span class="header-section-number">17.0.0.1</span> Warning ! <a href="#warning"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li>It’s better to compare variables that have only the same type. The package allows comparing variables that have not the same type. I do not recommend that because it can lead to errors :</li>
</ul>
<p>Let’s take a concrete example : <span v-highlightjs><code class="go" v-pre style="display: inline">.Price</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">float64</code></span> (defined in our data struct), <span v-highlightjs><code class="go" v-pre style="display: inline">100</code></span> is parsed as an integer. The two types are not the same. The result of the comparison is false (event if <span v-highlightjs><code class="go" v-pre style="display: inline">.Price</code></span> has the value one hundred). The text <span v-highlightjs><code class="go" v-pre style="display: inline">&lt;p&gt;Only 100$ it's a deal!&lt;/p&gt;</code></span>* will not appear on the HTML page when the template will execute.</p>
<pre v-highlightjs><code class="go" v-pre >{{ if eq .Price  100 }}
    &lt;p&gt;Only 100$ it&#39;s a deal!&lt;/p&gt;
{{ end }}</code></pre>
<ul>
<li><p>The comparison operands may have the following types :</p>
<ul>
<li><p>bool</p></li>
<li><p>int, int8, int16, int64</p></li>
<li><p>uint, uint8, uint16, uint64</p></li>
<li><p>uintptr</p></li>
<li><p>float32, float64</p></li>
<li><p>complex64, complex128</p></li>
<li><p>string</p></li>
</ul></li>
<li><p>We can use named types in comparisons.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type LogLevel int</code></pre>
<p>You can define a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">LogLevel</code></span> and use it in your template to make comparisons</p>
<div id="iteration" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Iteration <a href="#iteration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Comments, reviews, delivery options... etc. Data to display in a template often comes in the form of lists. The Go template engine has actions dedicated to iteration.</p>
<p>For instance, let’s add the property <span v-highlightjs><code class="go" v-pre style="display: inline">ShippingOptions</code></span> to our <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> type struct (the data that we will pass to the template) :</p>
<pre v-highlightjs><code class="go" v-pre >// template/iteration/main.go 

//...

type Product struct {
    //...
    ShippingOptions []string
}
//...
teaPot := Product{
        //...
        ShippingOptions: []string{&quot;Extra Priority&quot;, &quot;Normal&quot;, &quot;Low Priority&quot;}}
err = tmpl.Execute(w, teaPot)</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ShippingOptions</code></span> is a slice of strings. Then in our template, we can iterate through this slice with the <span v-highlightjs><code class="go" v-pre style="display: inline">range</code></span> action :</p>
<pre v-highlightjs><code class="go" v-pre >// template
&lt;ul&gt;
    {{range $index, $element := .ShippingOptions}}
       &lt;li&gt;{{ $element }}&lt;/li&gt;
    {{end}}
&lt;/ul&gt;</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">range</code></span> action will launch an iteration over the elements of <span v-highlightjs><code class="go" v-pre style="display: inline">ShippingOptions</code></span>. After range, you have to provide two variables names; the first is for the index (<span v-highlightjs><code class="go" v-pre style="display: inline">$index</code></span>), the second is for the element under that index (<span v-highlightjs><code class="go" v-pre style="display: inline">$element</code></span>).</p>
<p>We can then use those two variables inside the <span v-highlightjs><code class="go" v-pre style="display: inline">range</code></span> loop (which is delimited by a <span v-highlightjs><code class="go" v-pre style="display: inline">end</code></span> action). The variables <span v-highlightjs><code class="go" v-pre style="display: inline">$index</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">$element</code></span> are defined in the scope of the range loop.</p>
<p>The previous template will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;ul&gt;
        &lt;li&gt;Extra Priority&lt;/li&gt;
        &lt;li&gt;Normal&lt;/li&gt;
        &lt;li&gt;Low Priority&lt;/li&gt;
&lt;/ul&gt;</code></pre>
<p>You can also use a shorter version to iterate over a slice in a template :</p>
<pre v-highlightjs><code class="go" v-pre >{{range .ShippingOptions}}
    &lt;li&gt;{{ . }}&lt;/li&gt;
{{end}}</code></pre>
<div id="get-the-value-defined-at-a-specific-index" class="anchor"></div>
<h1 data-number="19"><span class="header-section-number">19</span> Get the value defined at a specific index <a href="#get-the-value-defined-at-a-specific-index"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">index</code></span> builtin allows you to get the value from an array, a slice, or a map from its index. To make this more clear, let’s take an example. If you want to get the element at index 0 into the slice <span v-highlightjs><code class="go" v-pre style="display: inline">.ShippingOptions</code></span> you can use this syntax :</p>
<pre v-highlightjs><code class="go" v-pre >//template
{{ $firstDelOpt := index .ShippingOptions 0}}
&lt;p&gt;First delivery option is {{ $firstDelOpt }}&lt;/p&gt;</code></pre>
<p>The value of <span v-highlightjs><code class="go" v-pre style="display: inline">.ShippingOptions[0]</code></span>. is assigned to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">$firstDelOpt</code></span>. We can then use this variable in the rest of the template.</p>
<p>This template will output :</p>
<pre v-highlightjs><code class="go" v-pre >&lt;p&gt;First delivery option is Extra Priority&lt;/p&gt;</code></pre>
<p>We can use the index function with multi-dimensional slices. Let’s add a property <span v-highlightjs><code class="go" v-pre style="display: inline">Notes</code></span> (a two-dimensional slice) to our type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Product</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
    //..
    Notes [][]int
}</code></pre>
<p>This 2D slice represents the notes of users for a product. Each row represent a notation, each column represents the note for a criterion :</p>
<pre v-highlightjs><code class="go" v-pre >notes := [][]int{}
note1 := []int{1, 2, 3}
note2 := []int{4, 5, 6}
notes = append(notes, note1)
notes = append(notes, note2)</code></pre>
<p>Here we have two notes (<span v-highlightjs><code class="go" v-pre style="display: inline">note1</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">note2</code></span>) with three criteria each. To retrieve the second criterion of the first note inside our template we can use the <span v-highlightjs><code class="go" v-pre style="display: inline">index</code></span> function like this :</p>
<pre v-highlightjs><code class="go" v-pre >{{ $note := index .Notes 0 1}}
&lt;p&gt;First note, second criterion : {{$note}}&lt;/p&gt;</code></pre>
<p>Here we provide three arguments to index; the first one is our 2D slice the two others are the indices. Here the value of <span v-highlightjs><code class="go" v-pre style="display: inline">.Notes[0][1]</code></span> is retrieved (the first element of a slice is at index 0, then second at index 1).</p>
<div id="embed-template-files-into-the-go-binary" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="20"><span class="header-section-number">20</span> Embed template files into the Go Binary <a href="#embed-template-files-into-the-go-binary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go 1.16 introduce a new standard package named <span v-highlightjs><code class="go" v-pre style="display: inline">embed</code></span>. With this package, you can include in your program binary your template files (or other files).</p>
<p>Let’s say that your template files are located in a views dir. You can embed all files located into the views directory with the <span v-highlightjs><code class="go" v-pre style="display: inline">//go:embed views/*</code></span> directive.</p>
<p>When the program is compiled, Go will populate <span v-highlightjs><code class="go" v-pre style="display: inline">views</code></span> with all files located into the <span v-highlightjs><code class="go" v-pre style="display: inline">views</code></span> directory.</p>
<pre v-highlightjs><code class="go" v-pre >// template/basic-embed/main.go 
package main

import (
    &quot;embed&quot;
    &quot;html/template&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

//go:embed views/*
var views embed.FS

func main {
    tmpl, err := template.ParseFS(views, &quot;views/product.html&quot;)
    if err != nil {
        http.Error(w, &quot;Something went wrong&quot;, http.StatusInternalServerError)
        return
    }
    // use the template
}</code></pre>
<p>Then you can use the function <span v-highlightjs><code class="go" v-pre style="display: inline">template.ParseFS</code></span> to load and parse your template files.</p>
<div id="debugging-a-template" class="anchor"></div>
<h1 data-number="21"><span class="header-section-number">21</span> Debugging a template <a href="#debugging-a-template"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>During the development phase, you can use this technique to improve your productivity :</p>
<p>Inside the template, add this line :</p>
<pre v-highlightjs><code class="go" v-pre >{{ printf &quot;%#v&quot; . }}</code></pre>
<p>This will output (in a human-readable mode) the properties keys and values passed to the template engine. Here is an example :</p>
<pre v-highlightjs><code class="go" v-pre >main.Product{Name:&quot;Red Tea Pot 250ml&quot;, Price:&quot;19.99&quot;, Description:&quot;Test&quot;, ShippingDate:time.Time{wall:0xbeffc6aedb25e3e0, ext:18903963863, loc:(*time.Location)(0x164dd40)}, Sale:true, SaleImagePath:[]string{&quot;img.jpg&quot;}}</code></pre>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="22"><span class="header-section-number">22</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="22.1"><span class="header-section-number">22.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>When you iterate over a slice with <span v-highlightjs><code class="go" v-pre style="display: inline">range</code></span> how to print the slice elements values?</p></li>
<li><p>True or False. To generate web pages templates, you can use the two following packages: <span v-highlightjs><code class="go" v-pre style="display: inline">text/template</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">html/template</code></span>.</p></li>
<li><p>How to load a template stored on a file?</p></li>
<li><p>How to load a template stored inside a variable?</p></li>
<li><p>How to print a date into a template with the format -01-02?</p></li>
<li><p>How to inject a template into another template?</p></li>
<li><p>How to embed templates file in your Go binary?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="22.2"><span class="header-section-number">22.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>When you iterate over a slice with a range, how to print the slice elements values?</p>
<ol type="1">
<li><p>With <span v-highlightjs><code class="go" v-pre style="display: inline">{{ . }}</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">{{range  .ShippingOptions}}    &lt;li&gt;{{ . }}&lt;/li&gt;{{end}}</code></span></p></li>
</ol></li>
<li><p>True or False. To generate web pages template, you can use the two following packages: <span v-highlightjs><code class="go" v-pre style="display: inline">text/template</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">html/template</code></span>.</p>
<ol type="1">
<li><p>It is technically true.</p></li>
<li><p>However, this is not recommended.</p></li>
<li><p>The package <span v-highlightjs><code class="go" v-pre style="display: inline">html/template</code></span> offers an extra layer of security (sanitization of data injected into the templates)</p></li>
</ol></li>
<li><p>How to load a template written on a file?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">tmpl, err := template.ParseFiles("./views/product-dynamic.html")</code></span></li>
</ol></li>
<li><p>How to load a template stored inside a variable?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">tmpl, err := template.New("test").Parse("Hello {{.}}")</code></span></li>
</ol></li>
<li><p>How to print a date into a template with the format -01-02?</p>
<ol type="1">
<li><p>Given that you have a field <span v-highlightjs><code class="go" v-pre style="display: inline">ShippingDate</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> you can do the following :</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">{{.ShippingDate.Format "2006-01-02"}}</code></span></p></li>
</ol></li>
<li><p>How to inject a template into another template?</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">{{ template "header" . }}</code></span></li>
</ol></li>
<li><p>How to embed templates file in your Go binary?</p>
<ol type="1">
<li>Use the directive <span v-highlightjs><code class="go" v-pre style="display: inline">//go:embed views/*</code></span></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="23"><span class="header-section-number">23</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Go has a built-in template engine.</p></li>
<li><p>A template engine will combine a template with data to produce a final document.</p></li>
<li><p>The package <span v-highlightjs><code class="go" v-pre style="display: inline">template/html</code></span> is designed to produce HTML documents</p></li>
<li><p>This package offers a security layer to protect your generated documents against common attacks.</p></li>
<li><p>You should always validate data generated by the user against restrictive rules.</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">template/text</code></span> is designed to produce text documents. It does not offer the same security features as <span v-highlightjs><code class="go" v-pre style="display: inline">template/html</code></span></p>
<ul>
<li><p>To print a field of a type struct use the notation <span v-highlightjs><code class="go" v-pre style="display: inline">{{ .NameOfField }}</code></span></p></li>
<li><p>You can call methods in templates : <span v-highlightjs><code class="go" v-pre style="display: inline">{{ .MyFunc "arg1""arg2" }}</code></span>. Arguments follow the name of the method</p></li>
<li><p>A variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span> can be formatted easily : <span v-highlightjs><code class="go" v-pre style="display: inline">{{ .CreateTime.Format "2006-01-02" }}</code></span></p></li>
<li><p>Templates can be <strong>nested</strong>. A template can be included in another template.</p></li>
<li><p>To define a template use <strong>define</strong> : <span v-highlightjs><code class="go" v-pre style="display: inline">{{ define "header" }} your template {{ end }}</code></span></p></li>
<li><p>Then you can “inject” this template into another one with : <span v-highlightjs><code class="go" v-pre style="display: inline">{{ template "header" . }}</code></span></p></li>
<li><p>You can declare variables with the following syntax : <span v-highlightjs><code class="go" v-pre style="display: inline">{{ $var2 := .Name}}</code></span></p></li>
<li><p>To iterate over a slice and array or a map, you can use <span v-highlightjs><code class="go" v-pre style="display: inline">range</code></span></p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >&lt;ul&gt;
    {{range $index, $element := .ShippingOptions}}
       &lt;li&gt;{{ $element }}&lt;/li&gt;
    {{end}}
&lt;/ul&gt;</code></pre>
<ul>
<li><p>You can use predeclared global functions : <span v-highlightjs><code class="go" v-pre style="display: inline">and</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">or</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">not</code></span>, .… a complete list can be found here : https://golang.org/pkg/text/template/#hdr-Functions</p></li>
<li><p>You can add custom functions to your templates :</p>
<pre v-highlightjs><code class="go" v-pre >funcs := template.FuncMap{&quot;capitalizeFirstLetter&quot;: capitalizeFirstLetter}

tmpl, err := template.New(&quot;product-dynamic.html&quot;).Funcs(funcs).ParseFiles(&quot;./views/product-dynamic.html&quot;)
// check error...</code></pre></li>
<li><p>From Go 1.16, you can easily <strong>embed</strong> your templates into your binary file with <span v-highlightjs><code class="go" v-pre style="display: inline">//go:embed</code></span></p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >//go:embed views/*
var views embed.FS

//...

tmpl, err := template.ParseFS(views, &quot;views/product.html&quot;)
//...</code></pre>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
<BuyCopyInvite></BuyCopyInvite>
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap32Templates"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap31Logging'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Logging</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap33ApplicationConfiguration'}">
									<p><u><small>Next</small></u></p>
									<p><small>Application Configuration</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Templates - Practical Go Lessons"
const description = "A template is the skeleton of a web page. It defines its layout and where dynamic data will be injected when a user sends a request to the web server. How to create HTML templates with GO. How to create text templates with Go"

export default {
  name: "Chap32Templates",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
